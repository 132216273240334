import React, { useEffect, useState, useCallback } from "react";
import styled, { ThemeProvider } from "styled-components";

import { toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { SIZE, SIZE_MEDIUM, SIZE_LARGE, SIZE_XLARGE } from "constants/themes";
import { MESSAGE_UPDATED, POSITION_RELOAD } from "constants/webview";

import {
  STATUS_PICKUP,
  STATUS_DELIVERY_WAITING,
  STATUS_DELIVERY_POSTPONED,
  STATUS_DELIVERY_STARTED,
  STATUS_CS,
  DELIVERY_TYPE_NORMAL,
  DELIVERY_TYPE_PICKUP,
  DELIVERY_TYPE_RETURN,
} from "Models";

import {
  useCommonStore,
  useDeliveriesStore,
  useOrderStore,
  useMapStore,
  useModalStore,
  useReallocationStore,
  useRidersStore,
} from "stores/hooks";

import { getOrder, getOrderWithOrderMode, getRearrange } from "utils/common";
import { initMap } from "utils/map";
import { appVersion } from "utils/params";

import BottomSummary from "components/Map/BottomSummary";
import BottomButtons from "components/Map/BottomButtons";
import CountSummary from "components/Map/CountSummary";
import OrderGuide from "components/Map/OrderGuide";
import RearrangeGuide from "components/Map/RearrangeGuide";
import TrackingMarkers from "components/Map/TrackingMarkers";

import OrderConfirmModal from "modals/OrderConfirmModal";
import ResetConfirmModal from "modals/ResetConfirmModal";
import RidersModal from "modals/RidersModal";
import SettingModal from "modals/SettingModal";

import { TMAP } from "constants/links";

import { checkNearbyMarkers } from "utils/deliveries";

const MapContainer = styled.div`
  .info-title {
    font-weight: bold;
    font-size: ${({ theme }) => theme.font16}px;
    display: flex;
    flex-wrap: wrap;
  }

  .info-spot {
    font-weight: bold;
    font-size: ${({ theme }) => theme.font14}px;
  }

  .info-base-address {
    font-weight: bold;
    font-size: ${({ theme }) => theme.font14}px;
  }

  .info-address {
    color: #828182;
    font-size: ${({ theme }) => theme.font14}px;
  }

  .info-address.bold {
    font-weight: 700;
    color: #000000;
  }

  .info-button-wrap {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    z-index: 10;
  }

  .btn {
    flex: 1;
    background-color: #2bd723;
    font-size: 16px;
    padding: 7px 10px;
    color: #fff;
    width: 100%;
    border-radius: 0;
  }

  .btn.btn-border {
    background-color: transparent;
    border: 1px solid #2bd723;
    color: #2bd723;
  }

  .marker-address {
    font-size: ${({ theme }) => theme.font10}px;
    position: absolute;
    width: 80px;
    text-align: center;
    top: 40px;
    padding: 3px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .marker-order {
    font-size: ${({ theme }) => theme.font12}px;
    position: absolute;
    top: 18px;
    right: 24px;
    color: #fff;
    background-color: #d84d50;
    border: 1px solid #fff;
    border-radius: 2px;
    min-width: 20px;
    text-align: center;
  }
`;

const Button = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 10px;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1.7px 4px 0 rgba(0, 0, 0, 0.2);
`;

// 현재 위치 조회
const ButtonCurrent = styled(Button)`
  background-image: url(/assets/images/map/icon-current.svg);
  background-size: 24px;
`;

// 새로고침
const ButtonReload = styled(Button)`
  background-image: url(/assets/images/map/icon-refresh.svg);
  background-size: 16px;
`;

// 설정
const ButtonSetting = styled(Button)`
  background-image: url(/assets/images/map/icon-setting.svg);
  background-size: 22px;
`;

// 지도
const MapConatiner = styled.div`
  width: 100%;
  height: 100vh;
`;

const TopWrap = styled.div`
  position: absolute;
  top: 10px;
  z-index: 1000;
  width: 100%;
  background: rgba(0, 0, 0, 0.15);
  padding: 6px 0;
  ${({ hide }) => hide && `display: none;`};
`;

const BottomWrap = styled.div`
  position: absolute;
  bottom: 12px;
  z-index: 1000;
  width: 100%;

  ${({ hide }) => hide && `display: none;`}
`;

const BottomButtonsWrap = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
`;

let markers = [];
let markersSoojione = [];
let polylines = [];
let infoWindows = [];

toast.configure({
  transition: Flip,
  autoClose: 1000,
  draggable: false,
  closeButton: false,
});

function Map() {
  const {
    count,

    statuses,

    markerList,
    markerListWithSameplace,
    deliveryStartList,
    orderList,

    fetched,

    deliveriesAllocated,

    limitedHoursCount,

    deliveryStartBookIds,
  } = useDeliveriesStore().state;
  const { size } = useCommonStore().state;
  const { ...commonActions } = useCommonStore();

  const { ...deliveriesActions } = useDeliveriesStore();

  const { isOrderMode, newOrderList } = useOrderStore().state;
  const { ...orderActions } = useOrderStore();

  const { isRearrangeMode, selectedDeliveries } = useReallocationStore().state;
  const { ...reallocationActions } = useReallocationStore();

  const { map, showHideMarkers, showMarkerAddress, currentCenter, linkOthers } =
    useMapStore().state;
  const { ...mapActions } = useMapStore();

  const { modals } = useModalStore().state;
  const { openModal, closeModal } = useModalStore();

  const { ...ridersActions } = useRidersStore();

  // TODO: 리로드 함수로 변경
  const [reload, setReload] = useState(false);

  const handleChangeReload = useCallback((_reload = true) => {
    setReload(_reload);
  }, []);

  useEffect(() => {
    fetchAll();
    // fetchAllocated();
    fetchCount();
    fetchLimitedHoursCount();

    handleChangeReload(false);
  }, [statuses, reload]);

  const fetchAll = async () => {
    try {
      await deliveriesActions.fetchAll();
    } catch (e) {
      // TODO: @aki - 에러 처리 (toast 등)
    }
  };

  const fetchCount = async () => {
    try {
      await deliveriesActions.fetchCount();
    } catch (e) {
      // TODO: @aki - 에러 처리 (toast 등)
    }
  };

  const fetchLimitedHoursCount = async () => {
    try {
      await deliveriesActions.fetchLimitedHoursCount();
    } catch (e) {
      // TODO: @aki - 에러 처리 (toast 등)
      // console.log(e);
    }
  };

  const reloadDeliveries = () => {
    handleChangeReload(true);
  };

  // TODO: useRef 로 정의해서 주소값을 이벤트리스너에 넣기
  const receiveMessage = (e) => {
    if (e.data) {
      if (e.data === POSITION_RELOAD) {
        handleChangeReload(true);
      } else if (
        e.data === SIZE_MEDIUM ||
        e.data === SIZE_LARGE ||
        e.data === SIZE_XLARGE
      ) {
        commonActions.setSize(e.data);
      } else {
        const message = JSON.parse(e.data);
        if (message.type === "favoRiders") {
          ridersActions.setFavoRiders(message.favoRiders);
        } else if (message.type === "deliveryStartBookIds") {
          deliveriesActions.setDeliveryStartBookIds(
            message.deliveryStartBookIds,
          );
        } else {
          mapActions.updateCurrentPostion(message);
        }
      }
    }
  };

  useEffect(() => {
    initMap(() => {
      mapActions.drawMap({ zoom: 15 });
    });

    setTimeout(() => {
      document.addEventListener("message", receiveMessage);
    }, 200);

    return () => {
      document.removeEventListener("message", receiveMessage);

      if (window.naver) {
        window.naver.maps.Event.removeListener();
      }
    };
  }, []);

  const handleClickStartRearrange = () => {
    handleChangeReload(true);
    reallocationActions.startRearrangeMode();
  };

  const handleClickCancelRearrangeMode = () => {
    if (
      window.confirm(
        "배차전환 취소하시겠습니까? 취소 시 현재까지 선택된 마커가 모두 삭제됩니다.",
      )
    ) {
      handleChangeReload(true);
      reallocationActions.cancelRearrangeMode();
    }
  };

  const selectDelivery = ({
    bookId,
    status,
    statusString,
    statusRearrangeKey,
    samePlaces,
    reallocatedRiderId,
  }) => {
    if (
      selectedDeliveries.length > 0 &&
      selectedDeliveries[0].status !== status
    ) {
      toast(`${selectedDeliveries[0].statusString}건만 체크해주세요`, {
        className: "toast-background",
        bodyClassName: "toast-body",
        progressClassName: "fancy-progress-bar",
      });
    } else {
      reallocationActions.selectDelivery({
        bookId,
        status,
        statusString,
        statusRearrangeKey,
        samePlaces,
        reallocatedRiderId,
      });
    }
  };

  // 배송순서 지정
  const handleClickStartOrder = () => {
    if (orderList.length > 0) {
      // 이미 순서 지정이 되어 있을 경우
      openModal(
        <ResetConfirmModal
          key="reset-confirm-modal"
          startOrder={setOrderMode}
        />,
      );
    } else {
      setOrderMode();
    }
  };

  const setOrderMode = () => {
    if (statuses.length === 1 && statuses.includes(STATUS_DELIVERY_STARTED)) {
      handleChangeReload(true);
    } else {
      deliveriesActions.setStatuses([STATUS_DELIVERY_STARTED]);
    }

    orderActions.startOrderMode();

    closeModal();
  };

  const selectOrderDelivery = ({
    bookId,
    status,
    receiverAddress,
    samePlaces,
  }) => {
    if (status === STATUS_DELIVERY_STARTED) {
      orderActions.selectOrderDelivery({
        bookId,
        receiverAddress,
        samePlaces,
      });
    } else {
      toast(`배송 건만 체크해주세요`, {
        className: "toast-background",
        bodyClassName: "toast-body",
        progressClassName: "fancy-progress-bar",
      });
    }
  };

  const handleClickCancelOrderMode = () => {
    if (
      window.confirm(
        "순서지정을 취소하시겠습니까? 취소 시 현재까지 지정한 순서가 모두 삭제됩니다.",
      )
    ) {
      cancelOrderMode();
    }
  };

  const cancelOrderMode = () => {
    orderActions.cancelOrderMode();

    closeModal();

    deliveriesActions.resetStatuses();

    handleChangeReload(true);
  };

  // 마커 클릭 핸들러
  const getClickHandler = (seq) => {
    return function () {
      var marker = markers[seq],
        infoWindow = infoWindows[seq];

      //  폴리라인 삭제
      for (let i = 0; i < polylines.length; i++) {
        polylines[i].setMap(null);
      }
      // 수지원 마커 삭제
      for (let i = 0; i < markersSoojione.length; i++) {
        markersSoojione[i].setMap(null);
      }

      if (infoWindow.getMap()) {
        infoWindow.close();
      } else {
        if (isOrderMode) {
          selectOrderDelivery({
            bookId: marker.bookId,
            status: marker.status,
            receiverAddress:
              marker.receiverAddress || marker.receiverAddressRoad || "",
            samePlaces: marker.samePlaces,
          });
        } else if (isRearrangeMode) {
          selectDelivery({
            bookId: marker.bookId,
            status: marker.status,
            statusString: marker.statusString,
            statusRearrangeKey: marker.statusRearrangeKey,
            samePlaces: marker.samePlaces,
            reallocatedRiderId: marker.reallocatedRiderId,
          });
        } else {
          infoWindow.open(map, marker);

          if (
            marker.soojioneLimitedCustomized &&
            marker.soojioneLimitedCustomizedLngLat &&
            marker.status !== STATUS_PICKUP &&
            marker.statusAddressNotSupported !== STATUS_PICKUP
          ) {
            // soojione 마커

            let markerSoojiwonIcon = {
              size: new window.naver.maps.Size(23, 32),
              scaledSize: new window.naver.maps.Size(23, 32),
              content: `
                  <div class="marker soojione">
                    ${
                      showMarkerAddress === "ALL" &&
                      marker.soojioneLimitedCustomizedAddressRoadWithoutSigungu
                        ? `<div class="marker-address">
                        ${marker.soojioneLimitedCustomizedAddressRoadWithoutSigungu}</div>`
                        : ""
                    }
                  </div>
                `,
            };

            let markerSoojiwon = new window.naver.maps.Marker({
              map: map,
              position: new window.naver.maps.LatLng(
                marker.soojioneLimitedCustomizedLngLat?.latitude,
                marker.soojioneLimitedCustomizedLngLat?.longitude,
              ),
              title: "SOOJIWON",
              icon: markerSoojiwonIcon,
              zIndex: 100,
            });

            markersSoojione.push(markerSoojiwon);

            let polylinePath = [
              new window.naver.maps.LatLng(marker.latitude, marker.longitude),
              new window.naver.maps.LatLng(
                marker.soojioneLimitedCustomizedLngLat?.latitude,
                marker.soojioneLimitedCustomizedLngLat?.longitude,
              ),
            ];

            //위의 배열을 이용해 라인 그리기
            const polyline = new window.naver.maps.Polyline({
              path: polylinePath, //선 위치 변수배열
              strokeColor: "#FF0000", //선 색 빨강 #빨강,초록,파랑
              strokeOpacity: 0.8, //선 투명도 0 ~ 1
              strokeWeight: 4, //선 두께
              strokeStyle: "shortdash",
              map: map, //오버레이할 지도
            });

            polylines.push(polyline);
          }
        }
      }
    };
  };

  // 지도 영역 밖 마커
  const [hideMarkers, setHideMarkers] = useState([]);

  const updateMarkers = (map, markers) => {
    let mapBounds = map.getBounds();
    let marker, position;

    let _hiderMarkers = [];

    for (var i = 0; i < markers.length; i++) {
      marker = markers[i];
      position = marker.getPosition();

      if (mapBounds.hasLatLng(position)) {
        showMarker(map, marker);
      } else {
        hideMarker(marker);

        if (marker.title !== "SOOJIWON") {
          _hiderMarkers.push(marker);
        }
      }
    }

    setHideMarkers(_hiderMarkers);
  };

  const drawMarker = () => {
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);

      var infoWindow = infoWindows[i];

      if (infoWindow.getMap()) {
        infoWindow.close();
      }
    }

    for (let i = 0; i < polylines.length; i++) {
      polylines[i].setMap(null);
    }

    for (let i = 0; i < markersSoojione.length; i++) {
      markersSoojione[i].setMap(null);
    }

    markers = [];
    infoWindows = [];

    if (markerList.length > 0) {
      markerList.forEach((item) => {
        // 마커 옆 아이콘
        let isHighPrice = false;
        let isLimitedHours = false;
        let isReallocated = false;
        let receiverWaitDeliveriesCount = 0;
        let receiverStartedDeliveriesCount = 0;
        let receiverPostponedDeliveriesCount = 0;
        let isNearbyMarker = false;
        let isNearbyMarkerInOtherStatus = false;

        let isDeliveryPlace = false;

        // 같은 위치에 마커가 있을 경우 마커를 표시하지 않는다.
        // const prevMarkerIndex = markers.findIndex(
        //   (d) =>
        //     d.title !== item.bookId &&
        //     d.position.y === Number(item.latitude) &&
        //     d.position.x === Number(item.longitude),
        // );

        // if (prevMarkerIndex > -1) {
        //   markers.splice(prevMarkerIndex, 1);
        //   infoWindows.splice(prevMarkerIndex, 1);
        // }

        if (item.isHighPrice) {
          isHighPrice = true;
        }
        if (item.isLimitedHours) {
          isLimitedHours = true;
        }
        if (item.reallocated) {
          isReallocated = true;
        }
        if (
          (item.status === STATUS_PICKUP ||
            item.statusAddressNotSupported === STATUS_PICKUP) &&
          (item.type === DELIVERY_TYPE_PICKUP ||
            item.type === DELIVERY_TYPE_RETURN) &&
          item.deliveryPlace
        ) {
          isDeliveryPlace = true;
        }

        // 수거일경우 동일 주소 툴팁 표시 제외
        let pickupInfoWindowDone = false;

        // 상세 페이지 이동
        let linkingDetail = `
            window.ReactNativeWebView.postMessage('${JSON.stringify({
              type: "detail",
              bookId: item.bookId,
            })}');
          `;

        let titleAddressRoad = "";
        if (
          (item.status === STATUS_PICKUP ||
            item.statusAddressNotSupported === STATUS_PICKUP) &&
          item.senderAddressRoadWithoutSigungu
        ) {
          titleAddressRoad = item.senderAddressRoadWithoutSigungu.replace(
            / /g,
            "",
          );
        } else if (
          (item.status === STATUS_PICKUP ||
            item.statusAddressNotSupported === STATUS_PICKUP) &&
          item.senderAddressWithoutSigungu
        ) {
          titleAddressRoad = item.senderAddressWithoutSigungu.replace(/ /g, "");
        } else if (
          item.status !== STATUS_PICKUP &&
          item.statusAddressNotSupported !== STATUS_PICKUP &&
          item.receiverAddressRoadWithoutSigungu
        ) {
          titleAddressRoad = item.receiverAddressRoadWithoutSigungu.replace(
            / /g,
            "",
          );
        } else if (
          item.status !== STATUS_PICKUP &&
          item.statusAddressNotSupported !== STATUS_PICKUP &&
          item.receiverAddressWithoutSigungu
        ) {
          titleAddressRoad = item.receiverAddressWithoutSigungu.replace(
            / /g,
            "",
          );
        }

        let linkingOtherApp = `
            window.ReactNativeWebView.postMessage('${JSON.stringify({
              type: "linking",
              app: linkOthers,
              longitude: item.longitude,
              latitude: item.latitude,
              title: `${
                item.order ? `(${item.order})` : ""
              }${titleAddressRoad}`,
            })}');
          `;

        let linkingOtherAppRoute = `
            window.ReactNativeWebView.postMessage('${JSON.stringify({
              type: "route",
              app: linkOthers,
              longitude: item.longitude,
              latitude: item.latitude,
              title:
                (item.renderSenderName
                  ? item.renderSenderName.replace(/\//g, "").replace(/ /g, "")
                  : "") +
                (item.renderReceiverName
                  ? item.renderReceiverName.replace(/\//g, "").replace(/ /g, "")
                  : ""),
            })}');
          `;

        if (!appVersion) {
          linkingDetail = `window.ReactNativeWebView.postMessage('${item.bookId}');`;
        }

        // 동일수 하인 동일 주소의 개수
        let receiverWaitDeliveriesArr =
          item.sameReceiverDeliveriesObj.waiting || [];
        let receiverStartedDeliveriesArr =
          item.sameReceiverDeliveriesObj.started || [];
        let receiverPostponedDeliveriesArr =
          item.sameReceiverDeliveriesObj.postponed || [];

        if (
          (item.status === STATUS_DELIVERY_WAITING ||
            item.status === STATUS_DELIVERY_POSTPONED ||
            item.status === STATUS_DELIVERY_STARTED ||
            item.statusAddressNotSupported === STATUS_DELIVERY_STARTED) &&
          deliveriesAllocated &&
          deliveriesAllocated.length > 0
        ) {
          receiverWaitDeliveriesCount = receiverWaitDeliveriesArr.length;
          receiverStartedDeliveriesCount = receiverStartedDeliveriesArr.length;
          receiverPostponedDeliveriesCount =
            receiverPostponedDeliveriesArr.length;
        }

        // 근접 마커 여부 표시
        const { nearbyMarkersArr, nearbyMarkersInOtherStatusArr } =
          checkNearbyMarkers(item, markerListWithSameplace);
        if (nearbyMarkersArr.length > 0) {
          isNearbyMarker = true;
        } else {
          isNearbyMarker = false;
        }

        // 근접 마커 여부 표시(다른 상태값)
        if (nearbyMarkersInOtherStatusArr.length > 0) {
          isNearbyMarkerInOtherStatus = true;
        } else {
          isNearbyMarkerInOtherStatus = false;
        }

        // 툴팁 내용
        let infoWindowContent = ``;

        let orderingNumbersByDeliveryRiderId = [];
        let isUploadedOrderingNumbers = true;
        let orderingNumberByDeliveryRiderId = "";
        let isUploadedOrderingNumber = true;

        if (deliveryStartBookIds.length > 0) {
          const deliveryStartIndex = deliveryStartBookIds?.findIndex(
            (e) => e === item.bookId,
          );

          if (deliveryStartIndex > -1) {
            orderingNumbersByDeliveryRiderId.push(deliveryStartIndex + 1);

            orderingNumberByDeliveryRiderId = ` [${deliveryStartIndex + 1}]`;

            if (
              !item.orderingNumberByDeliveryRiderId ||
              deliveryStartIndex + 1 !== item.orderingNumberByDeliveryRiderId
            ) {
              isUploadedOrderingNumbers = false;
              isUploadedOrderingNumber = false;
            }
          }
        } else if (item.orderingNumberByDeliveryRiderId) {
          orderingNumbersByDeliveryRiderId.push(
            item.orderingNumberByDeliveryRiderId,
          );

          orderingNumberByDeliveryRiderId = ` [${item.orderingNumberByDeliveryRiderId}]`;
        }

        if (appVersion) {
          if (linkOthers === TMAP) {
            infoWindowContent += `
                <div class="info-button-wrap">
                  <button type="button" class="btn" onclick=${linkingOtherAppRoute}">경로 찾기</button>
                </div>
              `;
          } else {
            infoWindowContent += `
              <div class="info-button-wrap">
                <button type="button" class="btn btn-border" onclick=${linkingOtherApp}">위치 찾기</button>
                <button type="button" class="btn" onclick=${linkingOtherAppRoute}">경로 찾기</button>
              </div>
            `;
          }
        }

        infoWindowContent += `
            <div style="max-height: 278px; overflow: auto; padding-bottom: 40px; position: relative;">
              <div 
                class="info ${item.statusText} ${item.keyColor}" 
                style="padding: 10px; max-width: 250px" 
                onclick=${linkingDetail}
              >
                ${
                  item.status === STATUS_PICKUP ||
                  item.statusAddressNotSupported === STATUS_PICKUP
                    ? ""
                    : `
                  <div class="info-icons" style="margin-top: -5px;">
                    <div class="info-icon highPrice ${
                      !item.isHighPrice && "disabled"
                    }">
                      <img style="width: 6px;" src="/assets/images/map/icon-dollar.png" />
                    </div>
                    <div class="info-icon time ${
                      !item.isLimitedHours && "disabled"
                    }">
                      <img style="width: 6px;" src="/assets/images/map/icon-clock.png" />
                    </div>
                    <div class="info-icon rearrange ${
                      !item.reallocated && "disabled"
                    }">
                      <img style="width: 8px;" src="/assets/images/map/icon-rotation.png" />
                    </div>
                    <div class="info-icon deliveryWait ${
                      receiverWaitDeliveriesArr.length === 0 && "disabled"
                    }">
                      ${receiverWaitDeliveriesArr.length}
                    </div>
                    <div class="info-icon deliveryStart ${
                      receiverStartedDeliveriesArr.length === 0 && "disabled"
                    }">
                      ${receiverStartedDeliveriesArr.length}
                    </div>
                    <div class="info-icon deliveryPostponed ${
                      receiverPostponedDeliveriesArr.length === 0 && "disabled"
                    }">
                      <img style="width: 7px;" src="/assets/images/map/icon-postponed.png" />
                    </div>
                  </div>
                `
                }
                <div class="info-title ${item.statusText}">
                  <span style="color: ${item.keyColorHex}">
                    (${item.statusString})
                  </span><span style="color: ${
                    isUploadedOrderingNumber ? item.keyColorHex : "red"
                  }">${orderingNumberByDeliveryRiderId}</span>&nbsp;
                  ${item.bookId}&nbsp;
                  <span style="color: ${item.keyColorHex}">
                    ${item.typeString}
                  </span>
                </div>
                <div class="info-spot">
                  ${
                    item.status === STATUS_PICKUP ||
                    item.statusAddressNotSupported === STATUS_PICKUP
                      ? `${item.spotName} ${
                          item.spot ? `(${item.renderSenderName})` : ""
                        }`
                      : `${item.renderReceiverName}(${item.renderSenderName})`
                  }
                  <span class="c-red">
                    ${
                      item.status !== STATUS_PICKUP && item.customerAddressEng
                        ? " (영어주소)"
                        : ""
                    }
                  </span>
                </div>
                <div class="info-base-address">
                ${
                  item.type === DELIVERY_TYPE_NORMAL
                    ? `${item.displayReceiverBuildingDongNumber || "없음"}(${
                        item.renderAddressApiType
                      }정제)`
                    : item.type !== DELIVERY_TYPE_NORMAL
                    ? `${item.displaySenderBuildingDongNumber || "없음"}(${
                        item.renderAddressApiType
                      }정제)`
                    : ""
                } 
                ${
                  item.frontdoorPassword ? ` / ${item.frontdoorPassword}` : ""
                }        
                </div>
                <div class="info-address">
                  ${
                    item.status === STATUS_PICKUP ||
                    item.statusAddressNotSupported === STATUS_PICKUP
                      ? `${
                          item.deliveryPlace &&
                          (item.type === DELIVERY_TYPE_PICKUP ||
                            item.type === DELIVERY_TYPE_RETURN)
                            ? `<span class="bg-red">보관등록</span> `
                            : ""
                        }(출)${
                          item.refinedAddressRawResultError
                            ? `<span class="c-red">(주소확인${item.refinedAddressRawResult?.RCD3})</span>`
                            : ""
                        } ${
                          item.senderAddress || item.senderAddressRoad || ""
                        }, ${item.senderAddressDetail}`
                      : `${
                          item.soojioneLimitedCustomized
                            ? `<span class="bg-red">주의${item.distanceRefineToLimitedRefined}</span> `
                            : ""
                        }${
                          item.spotKurlyDrink || item.mustSignature
                            ? `<span class="bg-red">서명</span> `
                            : ""
                        }(도)${
                          item.refinedAddressRawResultError
                            ? `<span class="c-red">(주소확인${item.refinedAddressRawResult?.RCD3})</span>`
                            : ""
                        } ${item.displayAddress} ${
                          item.memoFromCustomer
                            ? `<span class="c-red">(${item.memoFromCustomer})</span>`
                            : ""
                        }`
                  }
                </div>
                <div class="info-address bold">
                  ${
                    item.status !== STATUS_PICKUP &&
                    item.statusAddressNotSupported !== STATUS_PICKUP &&
                    item.customerAddressEngGpt
                      ? `(GPT번역) ${item.customerAddressEngGpt}`
                      : ""
                  }
                </div>
                <div class="info-address">
                  ${
                    item.status === STATUS_PICKUP ||
                    item.statusAddressNotSupported === STATUS_PICKUP
                      ? `${
                          item.senderAddressRoadWithoutSigungu ||
                          item.senderAddressWithoutSigungu
                        } ${
                          item.senderAddressObj &&
                          item.senderAddressObj.building
                            ? ` ${item.senderAddressObj.building}`
                            : ""
                        }`
                      : `${
                          item.receiverAddressRoadWithoutSigungu ||
                          item.receiverAddressWithoutSigungu
                        }${
                          item.receiverAddressObj &&
                          item.receiverAddressObj.building
                            ? ` ${item.receiverAddressObj.building}`
                            : ""
                        }
                        ${
                          !item.receiverAddressRoadWithoutSigungu &&
                          item.receiverDong
                            ? `/${item.receiverDong}`
                            : ""
                        }`
                  }
                </div>
              </div>
          `;

        if (
          (item.status === STATUS_PICKUP ||
            item.statusAddressNotSupported === STATUS_PICKUP) &&
          item.type === DELIVERY_TYPE_NORMAL
        ) {
          pickupInfoWindowDone = true;
        }

        // 툴팁 : 동일 주소 목록
        if (item.samePlaces && item.samePlaces.length > 0) {
          item.samePlaces.forEach((same) => {
            if (
              same.status === STATUS_DELIVERY_WAITING ||
              same.status === STATUS_DELIVERY_POSTPONED ||
              same.status === STATUS_DELIVERY_STARTED ||
              same.statusAddressNotSupported === STATUS_DELIVERY_STARTED ||
              same.type === DELIVERY_TYPE_PICKUP ||
              same.type === DELIVERY_TYPE_RETURN ||
              !pickupInfoWindowDone
            ) {
              if (same.isHighPrice) {
                isHighPrice = true;
              }
              if (same.isLimitedHours) {
                isLimitedHours = true;
              }
              if (same.reallocated) {
                isReallocated = true;
              }
              if (
                (same.status === STATUS_PICKUP ||
                  same.statusAddressNotSupported === STATUS_PICKUP) &&
                (same.type === DELIVERY_TYPE_PICKUP ||
                  same.type === DELIVERY_TYPE_RETURN) &&
                same.deliveryPlace
              ) {
                isDeliveryPlace = true;
              }

              let linkingDetail = `
                  window.ReactNativeWebView.postMessage('${JSON.stringify({
                    type: "detail",
                    bookId: same.bookId,
                  })}');
                `;

              if (!appVersion) {
                linkingDetail = `window.ReactNativeWebView.postMessage('${same.bookId}');`;
              }

              let sameReceiverWaitDeliveriesArr =
                same.sameReceiverDeliveriesObj?.waiting || [];
              let sameReceiverStartedDeliveriesArr =
                same.sameReceiverDeliveriesObj?.started || [];
              let sameReceiverPostponedDeliveriesArr =
                same.sameReceiverDeliveriesObj?.postponed || [];

              if (
                (same.status === STATUS_DELIVERY_WAITING ||
                  same.status === STATUS_DELIVERY_POSTPONED ||
                  same.status === STATUS_DELIVERY_STARTED ||
                  same.statusAddressNotSupported === STATUS_DELIVERY_STARTED) &&
                deliveriesAllocated &&
                deliveriesAllocated.length > 0
              ) {
                if (
                  sameReceiverWaitDeliveriesArr.length >
                  receiverWaitDeliveriesCount
                ) {
                  receiverWaitDeliveriesCount =
                    sameReceiverWaitDeliveriesArr.length;
                }
                if (
                  sameReceiverStartedDeliveriesArr.length >
                  receiverStartedDeliveriesCount
                ) {
                  receiverStartedDeliveriesCount =
                    sameReceiverStartedDeliveriesArr.length;
                }
                if (
                  sameReceiverPostponedDeliveriesArr.length >
                  receiverPostponedDeliveriesCount
                ) {
                  receiverPostponedDeliveriesCount =
                    sameReceiverPostponedDeliveriesArr.length;
                }
              }

              let sameOrderingNumberByDeliveryRiderId = "";
              let sameIsUploadedOrderingNumber = true;

              if (deliveryStartBookIds.length > 0) {
                const deliveryStartIndex = deliveryStartBookIds?.findIndex(
                  (e) => e === same.bookId,
                );

                if (deliveryStartIndex > -1) {
                  orderingNumbersByDeliveryRiderId.push(deliveryStartIndex + 1);

                  sameOrderingNumberByDeliveryRiderId = ` [${
                    deliveryStartIndex + 1
                  }]`;

                  if (
                    !same.orderingNumberByDeliveryRiderId ||
                    deliveryStartIndex + 1 !==
                      same.orderingNumberByDeliveryRiderId
                  ) {
                    isUploadedOrderingNumbers = false;
                    sameIsUploadedOrderingNumber = false;
                  }
                }
              } else if (same.orderingNumberByDeliveryRiderId) {
                orderingNumbersByDeliveryRiderId.push(
                  same.orderingNumberByDeliveryRiderId,
                );

                sameOrderingNumberByDeliveryRiderId = ` [${same.orderingNumberByDeliveryRiderId}]`;
              }

              infoWindowContent += `
                  <div 
                    class="info ${same.statusText} ${same.keyColor}" 
                    style="padding: 5px 10px 10px; max-width: 250px" 
                    onclick=${linkingDetail}
                  >
                    ${
                      same.status === STATUS_PICKUP ||
                      same.statusAddressNotSupported === STATUS_PICKUP
                        ? ""
                        : `
                      <div class="info-icons">
                        <div class="info-icon highPrice ${
                          !same.isHighPrice && "disabled"
                        }">
                          <img style="width: 6px;" src="/assets/images/map/icon-dollar.png" />
                        </div>
                        <div class="info-icon time ${
                          !same.isLimitedHours && "disabled"
                        }">
                          <img style="width: 6px;" src="/assets/images/map/icon-clock.png" />
                        </div>
                        <div class="info-icon rearrange ${
                          !same.reallocated && "disabled"
                        }">
                          <img style="width: 8px;" src="/assets/images/map/icon-rotation.png" />
                        </div>
                        <div class="info-icon deliveryWait ${
                          sameReceiverWaitDeliveriesArr.length === 0 &&
                          "disabled"
                        }">
                          ${sameReceiverWaitDeliveriesArr.length}
                        </div>
                        <div class="info-icon deliveryStart ${
                          sameReceiverStartedDeliveriesArr.length === 0 &&
                          "disabled"
                        }">
                          ${sameReceiverStartedDeliveriesArr.length}
                        </div>
                        <div class="info-icon deliveryPostponed ${
                          sameReceiverPostponedDeliveriesArr.length === 0 &&
                          "disabled"
                        }">
                          <img style="width: 7px;" src="/assets/images/map/icon-postponed.png" />
                        </div>
                      </div>
                    `
                    }
                    <div class="info-title ${same.statusText}">
                      <span style="color: ${same.keyColorHex}">(${
                same.statusString
              })</span>
                      <span style="color: ${
                        sameIsUploadedOrderingNumber ? same.keyColorHex : "red"
                      }">${sameOrderingNumberByDeliveryRiderId}</span>&nbsp;
                      ${same.bookId}&nbsp;
                      <span style="color: ${same.keyColorHex}">${
                same.typeString
              }</span>
                    </div>
                    <div class="info-spot">
                      ${
                        same.status === STATUS_PICKUP ||
                        same.statusAddressNotSupported === STATUS_PICKUP
                          ? `${same.spotName} ${
                              same.spot ? `(${same.renderSenderName})` : ""
                            }`
                          : `${same.renderReceiverName}(${same.renderSenderName})`
                      }
                      <span class="c-red">
                        ${
                          same.status !== STATUS_PICKUP &&
                          same.statusAddressNotSupported !== STATUS_PICKUP &&
                          same.customerAddressEng
                            ? " (영어주소)"
                            : ""
                        }
                      </span>
                    </div>
                    <div class="info-base-address">
                      ${
                        same.type === DELIVERY_TYPE_NORMAL
                          ? `${
                              same.displayReceiverBuildingDongNumber || "없음"
                            }(${same.renderAddressApiType}정제)`
                          : same.type !== DELIVERY_TYPE_NORMAL
                          ? `${
                              same.displaySenderBuildingDongNumber || "없음"
                            }(${same.renderAddressApiType}정제)`
                          : ""
                      } 
                      ${
                        same.frontdoorPassword
                          ? ` / ${same.frontdoorPassword}`
                          : ""
                      }        
                    </div>
                    <div class="info-address">
                      ${
                        same.status === STATUS_PICKUP ||
                        same.statusAddressNotSupported === STATUS_PICKUP
                          ? `${
                              same.deliveryPlace &&
                              (same.type === DELIVERY_TYPE_PICKUP ||
                                same.type === DELIVERY_TYPE_RETURN)
                                ? `<span class="bg-red">보관등록</span> `
                                : ""
                            }(출)${
                              same.refinedAddressRawResultError
                                ? `<span class="c-red">(주소확인${same.refinedAddressRawResult?.RCD3})</span>`
                                : ""
                            } ${
                              same.senderAddress || same.senderAddressRoad || ""
                            }, ${same.senderAddressDetail}`
                          : `${
                              same.soojioneLimitedCustomized
                                ? `<span class="bg-red">주의${same.distanceRefineToLimitedRefined}</span> `
                                : ""
                            }${
                              same.spotKurlyDrink || same.mustSignature
                                ? `<span class="bg-red">서명</span> `
                                : ""
                            }(도)${
                              same.refinedAddressRawResultError
                                ? `<span class="c-red">(주소확인${same.refinedAddressRawResult?.RCD3})</span>`
                                : ""
                            } ${same.displayAddress} ${
                              same.memoFromCustomer
                                ? `<span class="c-red">(${same.memoFromCustomer})</span>`
                                : ""
                            }`
                      }
                    </div>
                    <div class="info-address bold">
                      ${
                        same.status !== STATUS_PICKUP &&
                        same.statusAddressNotSupported !== STATUS_PICKUP &&
                        same.customerAddressEngGpt
                          ? `(GPT번역) ${same.customerAddressEngGpt}`
                          : ""
                      }
                    </div>
                    <div class="info-address">
                      ${
                        same.status === STATUS_PICKUP ||
                        same.statusAddressNotSupported === STATUS_PICKUP
                          ? `${
                              same.senderAddressRoadWithoutSigungu ||
                              same.senderAddressWithoutSigungu
                            } ${
                              same.senderAddressObj &&
                              same.senderAddressObj.building
                                ? ` ${same.senderAddressObj.building}`
                                : ""
                            }`
                          : `${
                              same.receiverAddressRoadWithoutSigungu ||
                              same.receiverAddressWithoutSigungu
                            }${
                              same.receiverAddressObj &&
                              same.receiverAddressObj.building
                                ? ` ${same.receiverAddressObj.building}`
                                : ""
                            }${
                              !same.receiverAddressRoadWithoutSigungu &&
                              same.receiverDong
                                ? `/${same.receiverDong}`
                                : ""
                            }`
                      }
                    </div>
                  </div>
                `;

              if (
                (same.status === STATUS_PICKUP ||
                  same.statusAddressNotSupported === STATUS_PICKUP) &&
                same.type === DELIVERY_TYPE_NORMAL
              ) {
                pickupInfoWindowDone = true;
              }
            }
          });
        }

        // 툴팁 : 동일 주소 목록(배송건) - CS만 표시됨
        if (item.samePlacesDelivery && item.samePlacesDelivery.length > 0) {
          item.samePlacesDelivery.forEach((same) => {
            if (
              same.status === STATUS_DELIVERY_WAITING ||
              same.status === STATUS_DELIVERY_POSTPONED ||
              same.status === STATUS_DELIVERY_STARTED ||
              same.statusAddressNotSupported === STATUS_DELIVERY_STARTED ||
              same.type === DELIVERY_TYPE_PICKUP ||
              same.type === DELIVERY_TYPE_RETURN ||
              !pickupInfoWindowDone
            ) {
              if (same.isHighPrice) {
                isHighPrice = true;
              }
              if (same.isLimitedHours) {
                isLimitedHours = true;
              }
              if (same.reallocated) {
                isReallocated = true;
              }
              if (
                (same.status === STATUS_PICKUP ||
                  same.statusAddressNotSupported === STATUS_PICKUP) &&
                (same.type === DELIVERY_TYPE_PICKUP ||
                  same.type === DELIVERY_TYPE_RETURN) &&
                same.deliveryPlace
              ) {
                isDeliveryPlace = true;
              }

              let linkingDetail = `
                  window.ReactNativeWebView.postMessage('${JSON.stringify({
                    type: "detail",
                    bookId: same.bookId,
                  })}');
                `;

              if (!appVersion) {
                linkingDetail = `window.ReactNativeWebView.postMessage('${same.bookId}');`;
              }

              let sameReceiverWaitDeliveriesArr =
                same.sameReceiverDeliveriesObj?.waiting || [];
              let sameReceiverStartedDeliveriesArr =
                same.sameReceiverDeliveriesObj?.started || [];
              let sameReceiverPostponedDeliveriesArr =
                same.sameReceiverDeliveriesObj?.postponed || [];

              if (
                (same.status === STATUS_DELIVERY_WAITING ||
                  same.status === STATUS_DELIVERY_POSTPONED ||
                  same.status === STATUS_DELIVERY_STARTED ||
                  same.statusAddressNotSupported === STATUS_DELIVERY_STARTED) &&
                deliveriesAllocated &&
                deliveriesAllocated.length > 0
              ) {
                if (
                  sameReceiverWaitDeliveriesArr.length >
                  receiverWaitDeliveriesCount
                ) {
                  receiverWaitDeliveriesCount =
                    sameReceiverWaitDeliveriesArr.length;
                }
                if (
                  sameReceiverStartedDeliveriesArr.length >
                  receiverStartedDeliveriesCount
                ) {
                  receiverStartedDeliveriesCount =
                    sameReceiverStartedDeliveriesArr.length;
                }
                if (
                  sameReceiverPostponedDeliveriesArr.length >
                  receiverPostponedDeliveriesCount
                ) {
                  receiverPostponedDeliveriesCount =
                    sameReceiverPostponedDeliveriesArr.length;
                }
              }

              let sameOrderingNumberByDeliveryRiderId = "";
              let sameIsUploadedOrderingNumber = true;

              if (deliveryStartBookIds.length > 0) {
                const deliveryStartIndex = deliveryStartBookIds?.findIndex(
                  (e) => e === same.bookId,
                );

                if (deliveryStartIndex > -1) {
                  orderingNumbersByDeliveryRiderId.push(deliveryStartIndex + 1);

                  sameOrderingNumberByDeliveryRiderId = ` [${
                    deliveryStartIndex + 1
                  }]`;

                  if (
                    !same.orderingNumberByDeliveryRiderId ||
                    deliveryStartIndex + 1 !==
                      same.orderingNumberByDeliveryRiderId
                  ) {
                    isUploadedOrderingNumbers = false;
                    sameIsUploadedOrderingNumber = true;
                  }
                }
              } else if (same.orderingNumberByDeliveryRiderId) {
                orderingNumbersByDeliveryRiderId.push(
                  same.orderingNumberByDeliveryRiderId,
                );

                sameOrderingNumberByDeliveryRiderId = ` [${same.orderingNumberByDeliveryRiderId}]`;
              }

              infoWindowContent += `
                  <div 
                    class="info ${same.statusText} ${same.keyColor}" 
                    style="padding: 5px 10px 10px; max-width: 250px" 
                    onclick=${linkingDetail}
                  >
                    ${
                      same.status === STATUS_PICKUP ||
                      same.statusAddressNotSupported === STATUS_PICKUP
                        ? ""
                        : `
                      <div class="info-icons">
                        <div class="info-icon highPrice ${
                          !same.isHighPrice && "disabled"
                        }">
                          <img style="width: 6px;" src="/assets/images/map/icon-dollar.png" />
                        </div>
                        <div class="info-icon time ${
                          !same.isLimitedHours && "disabled"
                        }">
                          <img style="width: 6px;" src="/assets/images/map/icon-clock.png" />
                        </div>
                        <div class="info-icon rearrange ${
                          !same.reallocated && "disabled"
                        }">
                          <img style="width: 8px;" src="/assets/images/map/icon-rotation.png" />
                        </div>
                        <div class="info-icon deliveryWait ${
                          sameReceiverWaitDeliveriesArr.length === 0 &&
                          "disabled"
                        }">
                          ${sameReceiverWaitDeliveriesArr.length}
                        </div>
                        <div class="info-icon deliveryStart ${
                          sameReceiverStartedDeliveriesArr.length === 0 &&
                          "disabled"
                        }">
                          ${sameReceiverStartedDeliveriesArr.length}
                        </div>
                        <div class="info-icon deliveryPostponed ${
                          sameReceiverPostponedDeliveriesArr.length === 0 &&
                          "disabled"
                        }">
                          <img style="width: 7px;" src="/assets/images/map/icon-postponed.png" />
                        </div>
                      </div>
                    `
                    }
                    <div class="info-title ${same.statusText}">
                      <span style="color: ${same.keyColorHex}">(${
                same.statusString
              })</span><span style="color: ${
                sameIsUploadedOrderingNumber ? same.keyColorHex : "red"
              }">${sameOrderingNumberByDeliveryRiderId}</span>&nbsp;
                      ${same.bookId}&nbsp;
                      <span style="color: ${same.keyColorHex}">${
                same.typeString
              }</span>
                    </div>
                    <div class="info-spot">
                      ${
                        same.status === STATUS_PICKUP ||
                        same.statusAddressNotSupported === STATUS_PICKUP
                          ? `${same.spotName} ${
                              same.spot ? `(${same.renderSenderName})` : ""
                            }`
                          : `${same.renderReceiverName}(${same.renderSenderName})`
                      }
                      <span class="c-red">
                        ${
                          same.status !== STATUS_PICKUP &&
                          same.statusAddressNotSupported !== STATUS_PICKUP &&
                          same.customerAddressEng
                            ? " (영어주소)"
                            : ""
                        }
                      </span>
                    </div>
                    <div class="info-base-address">
                      ${
                        same.type === DELIVERY_TYPE_NORMAL
                          ? `${
                              same.displayReceiverBuildingDongNumber || "없음"
                            }(${same.renderAddressApiType}정제)`
                          : same.type !== DELIVERY_TYPE_NORMAL
                          ? `${
                              same.displaySenderBuildingDongNumber || "없음"
                            }(${same.renderAddressApiType}정제)`
                          : ""
                      } 
                      ${
                        same.frontdoorPassword
                          ? ` / ${same.frontdoorPassword}`
                          : ""
                      }        
                    </div>
                    <div class="info-address">
                      ${
                        same.status === STATUS_PICKUP ||
                        same.statusAddressNotSupported === STATUS_PICKUP
                          ? `${
                              same.deliveryPlace &&
                              (same.type === DELIVERY_TYPE_PICKUP ||
                                same.type === DELIVERY_TYPE_RETURN)
                                ? `<span class="bg-red">보관등록</span> `
                                : ""
                            }(출)${
                              same.refinedAddressRawResultError
                                ? `<span class="c-red">(주소확인${same.refinedAddressRawResult?.RCD3})</span>`
                                : ""
                            } ${
                              same.senderAddress || same.senderAddressRoad || ""
                            }, ${same.senderAddressDetail}`
                          : `${
                              same.soojioneLimitedCustomized
                                ? `<span class="bg-red">주의${same.distanceRefineToLimitedRefined}</span> `
                                : ""
                            }${
                              same.spotKurlyDrink || same.mustSignature
                                ? `<span class="bg-red">서명</span> `
                                : ""
                            }(도)${
                              same.refinedAddressRawResultError
                                ? `<span class="c-red">(주소확인${same.refinedAddressRawResult?.RCD3})</span>`
                                : ""
                            } ${same.displayAddress} ${
                              same.memoFromCustomer
                                ? `<span class="c-red">(${same.memoFromCustomer})</span>`
                                : ""
                            }`
                      }
                    </div>
                    <div class="info-address bold">
                      ${
                        same.status !== STATUS_PICKUP &&
                        same.statusAddressNotSupported !== STATUS_PICKUP &&
                        same.customerAddressEngGpt
                          ? `(GPT번역) ${same.customerAddressEngGpt}`
                          : ""
                      }
                    </div>
                    <div class="info-address">
                      ${
                        same.status === STATUS_PICKUP ||
                        same.statusAddressNotSupported === STATUS_PICKUP
                          ? `${
                              same.senderAddressRoadWithoutSigungu ||
                              same.senderAddressWithoutSigungu
                            } ${
                              same.senderAddressObj &&
                              same.senderAddressObj.building
                                ? ` ${same.senderAddressObj.building}`
                                : ""
                            }`
                          : `${
                              same.receiverAddressRoadWithoutSigungu ||
                              same.receiverAddressWithoutSigungu
                            }${
                              same.receiverAddressObj &&
                              same.receiverAddressObj.building
                                ? ` ${same.receiverAddressObj.building}`
                                : ""
                            }${
                              same.receiverAddressRoadWithoutSigungu &&
                              same.senderAddressWithoutSigungu
                                ? `/${same.senderAddressWithoutSigungu}`
                                : ""
                            }${
                              !same.receiverAddressRoadWithoutSigungu &&
                              same.receiverDong
                                ? `/${same.receiverDong}`
                                : ""
                            }`
                      }
                    </div>
                  </div>
                `;

              if (
                (same.status === STATUS_PICKUP ||
                  same.statusAddressNotSupported === STATUS_PICKUP) &&
                same.type === DELIVERY_TYPE_NORMAL
              ) {
                pickupInfoWindowDone = true;
              }
            }
          });
        }

        // 툴팁 : 동일 주소 목록(수거건) - 배송 출발 건/CS만 표시됨
        if (item.samePlacesPickup && item.samePlacesPickup.length > 0) {
          item.samePlacesPickup.forEach((same) => {
            if (
              same.status === STATUS_DELIVERY_WAITING ||
              same.status === STATUS_DELIVERY_POSTPONED ||
              same.status === STATUS_DELIVERY_STARTED ||
              same.statusAddressNotSupported === STATUS_DELIVERY_STARTED ||
              same.type === DELIVERY_TYPE_PICKUP ||
              same.type === DELIVERY_TYPE_RETURN ||
              !pickupInfoWindowDone
            ) {
              if (same.isHighPrice) {
                isHighPrice = true;
              }
              if (same.isLimitedHours) {
                isLimitedHours = true;
              }
              if (same.reallocated) {
                isReallocated = true;
              }
              if (
                (same.status === STATUS_PICKUP ||
                  same.statusAddressNotSupported === STATUS_PICKUP) &&
                (same.type === DELIVERY_TYPE_PICKUP ||
                  same.type === DELIVERY_TYPE_RETURN) &&
                same.deliveryPlace
              ) {
                isDeliveryPlace = true;
              }

              let linkingDetail = `
                  window.ReactNativeWebView.postMessage('${JSON.stringify({
                    type: "detail",
                    bookId: same.bookId,
                  })}');
                `;

              if (!appVersion) {
                linkingDetail = `window.ReactNativeWebView.postMessage('${same.bookId}');`;
              }

              let sameReceiverWaitDeliveriesArr =
                same.sameReceiverDeliveriesObj?.waiting || [];
              let sameReceiverStartedDeliveriesArr =
                same.sameReceiverDeliveriesObj?.started || [];
              let sameReceiverPostponedDeliveriesArr =
                same.sameReceiverDeliveriesObj?.postponed || [];

              if (
                (same.status === STATUS_DELIVERY_WAITING ||
                  same.status === STATUS_DELIVERY_POSTPONED ||
                  same.status === STATUS_DELIVERY_STARTED ||
                  same.statusAddressNotSupported === STATUS_DELIVERY_STARTED) &&
                deliveriesAllocated &&
                deliveriesAllocated.length > 0
              ) {
                if (
                  sameReceiverWaitDeliveriesArr.length >
                  receiverWaitDeliveriesCount
                ) {
                  receiverWaitDeliveriesCount =
                    sameReceiverWaitDeliveriesArr.length;
                }
                if (
                  sameReceiverStartedDeliveriesArr.length >
                  receiverStartedDeliveriesCount
                ) {
                  receiverStartedDeliveriesCount =
                    sameReceiverStartedDeliveriesArr.length;
                }
                if (
                  sameReceiverPostponedDeliveriesArr.length >
                  receiverPostponedDeliveriesCount
                ) {
                  receiverPostponedDeliveriesCount =
                    sameReceiverPostponedDeliveriesArr.length;
                }
              }

              let sameOrderingNumberByDeliveryRiderId = "";
              let sameIsUploadedOrderingNumber = true;

              if (deliveryStartBookIds.length > 0) {
                const deliveryStartIndex = deliveryStartBookIds?.findIndex(
                  (e) => e === same.bookId,
                );

                if (deliveryStartIndex > -1) {
                  orderingNumbersByDeliveryRiderId.push(deliveryStartIndex + 1);

                  sameOrderingNumberByDeliveryRiderId = ` [${
                    deliveryStartIndex + 1
                  }]`;

                  if (
                    !same.orderingNumberByDeliveryRiderId ||
                    deliveryStartIndex + 1 !==
                      same.orderingNumberByDeliveryRiderId
                  ) {
                    isUploadedOrderingNumbers = false;
                    sameIsUploadedOrderingNumber = false;
                  }
                }
              } else if (same.orderingNumberByDeliveryRiderId) {
                orderingNumbersByDeliveryRiderId.push(
                  same.orderingNumberByDeliveryRiderId,
                );

                sameOrderingNumberByDeliveryRiderId = ` [${same.orderingNumberByDeliveryRiderId}]`;
              }

              infoWindowContent += `
                  <div 
                    class="info ${same.statusText} ${same.keyColor}" 
                    style="padding: 5px 10px 10px; max-width: 250px" 
                    onclick=${linkingDetail}
                  >
                    ${
                      same.status === STATUS_PICKUP ||
                      same.statusAddressNotSupported === STATUS_PICKUP
                        ? ""
                        : `
                      <div class="info-icons">
                        <div class="info-icon highPrice ${
                          !same.isHighPrice && "disabled"
                        }">
                          <img style="width: 6px;" src="/assets/images/map/icon-dollar.png" />
                        </div>
                        <div class="info-icon time ${
                          !same.isLimitedHours && "disabled"
                        }">
                          <img style="width: 6px;" src="/assets/images/map/icon-clock.png" />
                        </div>
                        <div class="info-icon rearrange ${
                          !same.reallocated && "disabled"
                        }">
                          <img style="width: 8px;" src="/assets/images/map/icon-rotation.png" />
                        </div>
                        <div class="info-icon deliveryWait ${
                          sameReceiverWaitDeliveriesArr.length === 0 &&
                          "disabled"
                        }">
                          ${sameReceiverWaitDeliveriesArr.length}
                        </div>
                        <div class="info-icon deliveryStart ${
                          sameReceiverStartedDeliveriesArr.length === 0 &&
                          "disabled"
                        }">
                          ${sameReceiverStartedDeliveriesArr.length}
                        </div>
                        <div class="info-icon deliveryPostponed ${
                          sameReceiverPostponedDeliveriesArr.length === 0 &&
                          "disabled"
                        }">
                          <img style="width: 7px;" src="/assets/images/map/icon-postponed.png" />
                        </div>
                      </div>
                    `
                    }
                    <div class="info-title ${same.statusText}">
                      <span style="color: ${same.keyColorHex}">(${
                same.statusString
              })</span><span style="color: ${
                sameIsUploadedOrderingNumber ? same.keyColorHex : "red"
              }">${sameOrderingNumberByDeliveryRiderId}</span>&nbsp;
                      ${same.bookId}&nbsp;
                      <span style="color: ${same.keyColorHex}">${
                same.typeString
              }</span>
                    </div>
                    <div class="info-spot">
                      ${
                        same.status === STATUS_PICKUP ||
                        same.statusAddressNotSupported === STATUS_PICKUP
                          ? `${same.spotName} ${
                              same.spot ? `(${same.renderSenderName})` : ""
                            }`
                          : `${same.renderReceiverName}(${same.renderSenderName})`
                      }
                      <span class="c-red">
                        ${
                          same.status !== STATUS_PICKUP &&
                          same.statusAddressNotSupported !== STATUS_PICKUP &&
                          same.customerAddressEng
                            ? " (영어주소)"
                            : ""
                        }
                      </span>
                    </div>
                    <div class="info-base-address">
                      ${
                        same.type === DELIVERY_TYPE_NORMAL
                          ? `${
                              same.displayReceiverBuildingDongNumber || "없음"
                            }(${same.renderAddressApiType}정제)`
                          : same.type !== DELIVERY_TYPE_NORMAL
                          ? `${
                              same.displaySenderBuildingDongNumber || "없음"
                            }(${same.renderAddressApiType}정제)`
                          : ""
                      } 
                      ${
                        same.frontdoorPassword
                          ? ` / ${same.frontdoorPassword}`
                          : ""
                      }        
                    </div>
                    <div class="info-address">
                      ${
                        same.status === STATUS_PICKUP ||
                        same.statusAddressNotSupported === STATUS_PICKUP
                          ? `${
                              same.deliveryPlace &&
                              (same.type === DELIVERY_TYPE_PICKUP ||
                                same.type === DELIVERY_TYPE_RETURN)
                                ? `<span class="bg-red">보관등록</span> `
                                : ""
                            }(출)${
                              same.refinedAddressRawResultError
                                ? `<span class="c-red">(주소확인${same.refinedAddressRawResult?.RCD3})</span>`
                                : ""
                            } ${
                              same.senderAddress || same.senderAddressRoad || ""
                            }, ${same.senderAddressDetail}`
                          : `${
                              same.soojioneLimitedCustomized
                                ? `<span class="bg-red">주의${same.distanceRefineToLimitedRefined}</span> `
                                : ""
                            }${
                              same.spotKurlyDrink || same.mustSignature
                                ? `<span class="bg-red">서명</span> `
                                : ""
                            }(도)${
                              same.refinedAddressRawResultError
                                ? `<span class="c-red">(주소확인${same.refinedAddressRawResult?.RCD3})</span>`
                                : ""
                            } ${same.displayAddress} ${
                              same.memoFromCustomer
                                ? `<span class="c-red">(${same.memoFromCustomer})</span>`
                                : ""
                            }`
                      }
                    </div>
                    <div class="info-address bold">
                      ${
                        same.status !== STATUS_PICKUP &&
                        same.statusAddressNotSupported !== STATUS_PICKUP &&
                        same.customerAddressEngGpt
                          ? `(GPT번역) ${same.customerAddressEngGpt}`
                          : ""
                      }
                    </div>
                    <div class="info-address">
                      ${
                        same.status === STATUS_PICKUP ||
                        same.statusAddressNotSupported === STATUS_PICKUP
                          ? `${
                              same.senderAddressRoadWithoutSigungu ||
                              same.senderAddressWithoutSigungu
                            } ${
                              same.senderAddressObj &&
                              same.senderAddressObj.building
                                ? ` ${same.senderAddressObj.building}`
                                : ""
                            }`
                          : `${
                              same.receiverAddressRoadWithoutSigungu ||
                              same.receiverAddressWithoutSigungu
                            }${
                              same.receiverAddressObj &&
                              same.receiverAddressObj.building
                                ? ` ${same.receiverAddressObj.building}`
                                : ""
                            }${
                              same.receiverAddressRoadWithoutSigungu &&
                              same.senderAddressWithoutSigungu
                                ? `/${same.senderAddressWithoutSigungu}`
                                : ""
                            }${
                              !same.receiverAddressRoadWithoutSigungu &&
                              same.receiverDong
                                ? `/${same.receiverDong}`
                                : ""
                            }`
                      }
                    </div>
                  </div>
                `;

              if (
                (same.status === STATUS_PICKUP ||
                  same.statusAddressNotSupported === STATUS_PICKUP) &&
                same.type === DELIVERY_TYPE_NORMAL
              ) {
                pickupInfoWindowDone = true;
              }
            }
          });
        }

        infoWindowContent += "</div>";

        orderingNumbersByDeliveryRiderId.sort((a, b) => (a > b ? 1 : -1));

        // 마커 아이콘
        let markerIcon = {
          size: new window.naver.maps.Size(23, 32),
          scaledSize: new window.naver.maps.Size(23, 32),
          content: `
              <div class="marker ${item.statusText}${
            item.isPenaltyCorpUser ? "Penalty" : ""
          } ${isDeliveryPlace ? "pickupPlace" : ""} ${
            item.samePlacekeyColor || item.keyColor
          }">
                ${
                  isRearrangeMode
                    ? getRearrange(
                        isRearrangeMode,
                        selectedDeliveries,
                        item.bookId,
                      )
                    : orderingNumbersByDeliveryRiderId?.length > 0
                    ? orderingNumbersByDeliveryRiderId[0]
                    : ""
                }
                
                <div class="marker-plus-wrap">
                  ${
                    item.samePlacesCount || item.samePlacesPickupCount
                      ? `<div class="marker-plus">
                        ${
                          item.samePlacesCount > 0
                            ? `+${item.samePlacesCount}`
                            : ""
                        }${
                          item.samePlacesPickupCount > 0
                            ? `(${item.samePlacesPickupCount})`
                            : ""
                        }</div>`
                      : ""
                  }
                  
                  ${
                    isNearbyMarker
                      ? `<div class="marker-near"><img style="width: 14px;" src="/assets/images/map/icon-near.png" /></div>`
                      : ""
                  }
                  ${
                    isNearbyMarkerInOtherStatus
                      ? nearbyMarkersInOtherStatusArr.map(
                          (nearbyMarker, idx) =>
                            `<div class="marker-near delivery ${
                              nearbyMarker.status === STATUS_PICKUP &&
                              "deliveryPickup"
                            } ${
                              nearbyMarker.status === STATUS_DELIVERY_WAITING &&
                              "deliveryWait "
                            } ${
                              nearbyMarker.status === STATUS_DELIVERY_STARTED &&
                              "deliveryStart"
                            } ${
                              nearbyMarker.status ===
                                STATUS_DELIVERY_POSTPONED && "deliveryPostponed"
                            } ${nearbyMarker.status === STATUS_CS && "cs"}"
                        key=${idx}
                      >
                        <img style="width: 7px;" src="/assets/images/map/icon-nearplus.png" />
                      </div>`,
                        )
                      : ""
                  }
                </div>
                
                ${
                  item.status === STATUS_PICKUP ||
                  item.statusAddressNotSupported === STATUS_PICKUP
                    ? ``
                    : `
                  <div class="marker-icons ${
                    item.samePlacesCount ||
                    item.samePlacesPickupCount ||
                    isNearbyMarker ||
                    isNearbyMarkerInOtherStatus
                      ? "plus"
                      : ""
                  }">
                    ${
                      isHighPrice
                        ? '<div class="marker-icon highPrice"><img style="width: 6px;" src="/assets/images/map/icon-dollar.png" /></div>'
                        : ""
                    }
                    ${
                      isLimitedHours
                        ? '<div class="marker-icon time"><img style="width: 6px;" src="/assets/images/map/icon-clock.png" /></div>'
                        : ""
                    }
                    ${
                      isReallocated
                        ? '<div class="marker-icon rearrange"><img style="width: 8px;" src="/assets/images/map/icon-rotation.png" /></div>'
                        : ""
                    }
                    ${
                      receiverWaitDeliveriesCount > 0
                        ? `<div class="marker-icon deliveryWait"></div>`
                        : ""
                    }
                    ${
                      receiverStartedDeliveriesCount > 0
                        ? `<div class="marker-icon deliveryStart">${receiverStartedDeliveriesCount}</div>`
                        : ""
                    }
                    ${
                      receiverPostponedDeliveriesCount > 0
                        ? `<div class="marker-icon deliveryPostponed"><img style="width: 7px;" src="/assets/images/map/icon-postponed.png" /></div>`
                        : ""
                    }
                  </div>
                `
                }
                
                ${
                  item.customerAddressEng ||
                  // item.order ||
                  showMarkerAddress !== "NONE"
                    ? // orderingNumbersByDeliveryRiderId.length > 0
                      `<div class="marker-address">
                  ${item.customerAddressEng ? `(영어주소)<br/>` : ""}
                  ${
                    showMarkerAddress !== "NONE" && item.order
                      ? `${getOrder(
                          false,
                          orderList,
                          newOrderList,
                          item.order,
                          item.bookId,
                        )}<br/>`
                      : ""
                  }
                  ${
                    showMarkerAddress === "ALL"
                      ? item.status === STATUS_PICKUP ||
                        item.statusAddressNotSupported === STATUS_PICKUP
                        ? `${
                            item.senderAddressRoadWithoutSigungu ||
                            item.senderAddressWithoutSigungu
                          }${
                            item.senderAddressObj &&
                            item.senderAddressObj.building
                              ? `<br/>${item.senderAddressObj.building}`
                              : ""
                          }`
                        : `${
                            item.receiverAddressRoadWithoutSigungu ||
                            item.receiverAddressWithoutSigungu
                          }${
                            item.receiverAddressObj &&
                            item.receiverAddressObj.building
                              ? `<br/>${item.receiverAddressObj.building}`
                              : ""
                          }`
                      : ""
                  } <span style="${
                        isUploadedOrderingNumbers ? "" : "color: red;"
                      }">
                   ${
                     showMarkerAddress !== "NONE" &&
                     orderingNumbersByDeliveryRiderId.length
                       ? `[${orderingNumbersByDeliveryRiderId.join("] [")}]`
                       : ""
                   }
                   </span>
                  ${item.order ? `(#${item.order})` : ""}
                </div>`
                    : ""
                }
                ${
                  (isOrderMode &&
                    newOrderList.find((el) => el.bookId === item.bookId)) ||
                  (!isOrderMode && item.order)
                    ? `<div class="marker-order">
                      ${getOrderWithOrderMode(
                        isOrderMode,
                        orderList,
                        newOrderList,
                        item.order,
                        item.bookId,
                      )}
                    </div>`
                    : ""
                }
              </div>
            `,
        };

        // 마커
        let marker = new window.naver.maps.Marker({
          map: map,
          position: new window.naver.maps.LatLng(item.latitude, item.longitude),
          title: item.bookId,
          icon: markerIcon,
          zIndex: 100,
        });

        marker.status = item.status;
        marker.order = isRearrangeMode
          ? getRearrange(isRearrangeMode, selectedDeliveries, item.bookId)
          : getOrder(
              isOrderMode,
              orderList,
              newOrderList,
              item.order,
              item.bookId,
            );
        marker.bookId = item.bookId;
        marker.receiverAddress = item.receiverAddress;
        marker.samePlaces = item.samePlaces;
        marker.samePlacesPickup = item.samePlacesPickup;
        marker.statusString = item.statusString;
        marker.statusRearrangeKey = item.statusRearrangeKey;
        marker.reallocatedRiderId = item.reallocatedRiderId;

        marker.soojioneLimitedCustomized = item.soojioneLimitedCustomized;
        marker.soojioneLimitedCustomizedLngLat =
          item.soojioneLimitedCustomizedLngLat;
        marker.statusAddressNotSupported = item.statusAddressNotSupported;
        marker.soojioneLimitedCustomizedAddressRoadWithoutSigungu =
          item.soojioneLimitedCustomizedAddressRoadWithoutSigungu;
        marker.latitude = item.latitude;
        marker.longitude = item.longitude;

        // 툴팁
        let infoWindow = new window.naver.maps.InfoWindow({
          content: infoWindowContent,
        });

        markers.push(marker);
        infoWindows.push(infoWindow);
      });

      updateMarkers(map, markers);

      window.naver.maps.Event.addListener(map, "idle", function () {
        updateMarkers(map, markers);
      });

      for (var j = 0, jj = markers.length; j < jj; j++) {
        window.naver.maps.Event.addListener(
          markers[j],
          "click",
          getClickHandler(j),
        );
      }

      window.naver.maps.Event.addListener(map, "click", function () {
        for (var i = 0; i < markers.length; i++) {
          var infoWindow = infoWindows[i];

          if (infoWindow.getMap()) {
            infoWindow.close();
          }
        }
      });

      if (window.ReactNativeWebView) {
        if (appVersion) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ type: MESSAGE_UPDATED }),
          );
        } else {
          window.ReactNativeWebView.postMessage(MESSAGE_UPDATED);
        }
      }
    } else {
      if (window.ReactNativeWebView) {
        if (appVersion) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ type: MESSAGE_UPDATED }),
          );
        } else {
          window.ReactNativeWebView.postMessage(MESSAGE_UPDATED);
        }
      }
    }
  };

  useEffect(() => {
    if (map && fetched) {
      drawMarker();
    }
  }, [
    isOrderMode,
    fetched,
    map,
    markerList,
    newOrderList,
    selectedDeliveries,
    showMarkerAddress,
    deliveryStartBookIds,
  ]);

  function showMarker(map, marker) {
    if (marker.setMap()) return;
    marker.setMap(map);
  }

  function hideMarker(marker) {
    if (!marker.setMap()) return;
    marker.setMap(null);
  }

  // 배송 순서 지정
  const handleClickOrderDeliveries = () => {
    if (deliveryStartList.length !== newOrderList.length) {
      openModal(
        <OrderConfirmModal
          key="order-confirm-modal"
          changeOrder={submitOrderDeliveries}
        />,
      );
    } else {
      submitOrderDeliveries();
    }
  };

  const submitOrderDeliveries = async () => {
    try {
      let _orderList = [...newOrderList];

      deliveryStartList.forEach((deliver) => {
        let index = _orderList.findIndex((el) => el.bookId === deliver.bookId);
        if (index < 0)
          _orderList.push({
            bookId: deliver.bookId,
            order: null,
          });
      });

      const data = {
        orders: _orderList,
      };

      await orderActions.reorderDeliveries(data);

      toast(`순서 지정을 저장하였습니다.`, {
        className: "toast-background",
        bodyClassName: "toast-body",
        progressClassName: "fancy-progress-bar",
      });

      cancelOrderMode();

      handleChangeReload(true);
    } catch (e) {
      alert(`순서 지정에 실패하였습니다.. \n에러메시지: ${e.message}`);
    }
  };

  // 재배차 모달
  const handleClickRearrangeDeliveries = () => {
    if (selectedDeliveries.length > 0) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "favoRiders" }),
        );
      }

      openModal(
        <RidersModal key="riders-modal" onSubmitAfter={handleChangeReload} />,
      );
    } else {
      toast(`재배차할 배송건을 선택해주세요.`, {
        className: "toast-background",
        bodyClassName: "toast-body",
        progressClassName: "fancy-progress-bar",
      });
    }
  };

  // 배송 대기 시작
  const handleClickPrevDeliveries = async () => {
    let _prevDeliveries = [];
    let _status = null;
    selectedDeliveries.forEach((el) => {
      if (
        el.status === STATUS_DELIVERY_STARTED ||
        el.status === STATUS_DELIVERY_POSTPONED
      ) {
        _prevDeliveries.push(el.bookId);
        _status = el.status;
      }
    });

    if (_prevDeliveries.length > 0) {
      if (
        window.confirm(
          `${_prevDeliveries[0]} 포함 ${_prevDeliveries.length}건을 배송대기 상태로 변경하시겠습니까?`,
        )
      ) {
        try {
          await deliveriesActions.prevDeliveries({
            bookIds: _prevDeliveries,
            status: _status === STATUS_DELIVERY_STARTED ? 4 : 12,
          });

          toast(
            `${_prevDeliveries[0]} 포함 ${_prevDeliveries.length}건을 배송대기 상태로 변경처리하였습니다.`,
            {
              className: "toast-background",
              bodyClassName: "toast-body",
              progressClassName: "fancy-progress-bar",
            },
          );

          reallocationActions.cancelRearrangeMode();
          handleChangeReload(true);
        } catch (e) {
          alert(
            `배송대기 상태로 변경에 실패하였습니다. \n에러메시지: ${e.message}`,
          );
        }
      }
    } else {
      toast(`대기 상태로 변경할 배송건을 선택해주세요.`, {
        className: "toast-background",
        bodyClassName: "toast-body",
        progressClassName: "fancy-progress-bar",
      });
    }
  };

  // 배송 연기 시작
  const handleClickPostponedDeliveries = async () => {
    let _prevDeliveries = [];
    let _status = null;
    selectedDeliveries.forEach((el) => {
      if (
        el.status === STATUS_DELIVERY_STARTED ||
        el.status === STATUS_DELIVERY_WAITING
      ) {
        _prevDeliveries.push(el.bookId);
        _status = el.status;
      }
    });

    if (_prevDeliveries.length > 0) {
      if (
        window.confirm(
          `${_prevDeliveries[0]} 포함 ${_prevDeliveries.length}건을 배송연기 상태로 변경하시겠습니까?`,
        )
      ) {
        try {
          await Promise.all(
            _prevDeliveries.map((bookId) =>
              deliveriesActions.setPostponed(bookId),
            ),
          );

          toast(
            `${_prevDeliveries[0]} 포함 ${_prevDeliveries.length}건을 배송연기 상태로 변경처리하였습니다.`,
            {
              className: "toast-background",
              bodyClassName: "toast-body",
              progressClassName: "fancy-progress-bar",
            },
          );

          reallocationActions.cancelRearrangeMode();
          handleChangeReload(true);
        } catch (e) {
          alert(
            `배송연기 상태로 변경에 실패하였습니다. \n에러메시지: ${e.message}`,
          );
        }
      }
    } else {
      toast(`연기 상태로 변경할 배송건을 선택해주세요.`, {
        className: "toast-background",
        bodyClassName: "toast-body",
        progressClassName: "fancy-progress-bar",
      });
    }
  };

  // 지도 설정
  const handleClickSetting = () => {
    openModal(
      <SettingModal
        key="setting-modal"
        linkOthers={linkOthers}
        statuses={statuses}
        currentCenter={currentCenter}
        showHideMarkers={showHideMarkers}
        showMarkerAddress={showMarkerAddress}
        onSubmit={updateSetting}
      />,
    );
  };

  const updateSetting = (data) => {
    deliveriesActions.setStatuses(data.statuses);
    mapActions.setLinkOthers(data.linkOthers);
    mapActions.getCurrentPosition(data.currentCenter);
    mapActions.setShowHideMarkers(data.showHideMarkers === "true");
    mapActions.setShowMarkerAddress(data.showMarkerAddress);

    closeModal();
  };

  // 현재 위치 이동
  const handleClickCurrent = () => {
    mapActions.setMapCurrentCenter();
  };

  return (
    <ThemeProvider theme={size ? SIZE[size] : {}}>
      <MapContainer>
        <div className="map">
          {/** 배송 순서 클릭 가이드 */}
          <OrderGuide
            isShow={isOrderMode && !modals.length}
            orderListCount={newOrderList.length}
            deliveriesCount={deliveryStartList.length}
            onClickSubmit={handleClickOrderDeliveries}
            onClickCancel={handleClickCancelOrderMode}
          />
          {/** 재배차 클릭 가이드 */}
          <RearrangeGuide
            isShow={isRearrangeMode}
            rearrangeCount={selectedDeliveries.length}
            deliveriesCount={
              selectedDeliveries.length > 0
                ? selectedDeliveries[0].status === STATUS_PICKUP
                  ? count.pickups
                  : selectedDeliveries[0].status === STATUS_DELIVERY_WAITING
                  ? count.deliveriesBeforeStart
                  : selectedDeliveries[0].status === STATUS_DELIVERY_POSTPONED
                  ? count.deliveriesPostponed
                  : count.deliveriesStarted
                : count.pickups + count.deliveriesAllocated
            }
            onClickSubmitRearrange={handleClickRearrangeDeliveries}
            onClickSubmitPrev={handleClickPrevDeliveries}
            onClickSubmitPostponed={handleClickPostponedDeliveries}
            onClickCancel={handleClickCancelRearrangeMode}
          />
          <TopWrap hide={modals.length > 0 || isOrderMode || isRearrangeMode}>
            <CountSummary count={count} limitedHoursCount={limitedHoursCount} />
          </TopWrap>
          <BottomWrap
            hide={modals.length > 0 || isOrderMode || isRearrangeMode}
          >
            <BottomSummary
              statuses={statuses}
              linkOthers={linkOthers}
              currentCenter={currentCenter}
              showHideMarkers={showHideMarkers}
              showMarkerAddress={showMarkerAddress}
            />
            <BottomButtonsWrap>
              {/** 설정 */}
              <ButtonSetting onClick={handleClickSetting} />
              {/** 지도 하단 : 순서지정/재배차 버튼 */}
              <BottomButtons
                onClickStartOrder={handleClickStartOrder}
                onClickStartRearrange={handleClickStartRearrange}
              />
              {/** 현재위치 */}
              <ButtonCurrent onClick={handleClickCurrent} />
              {/** 새로고침 */}
              <ButtonReload onClick={reloadDeliveries} />
            </BottomButtonsWrap>
          </BottomWrap>
          {map && showHideMarkers && (
            <TrackingMarkers
              markers={hideMarkers}
              mapBounds={map.getBounds()}
            />
          )}{" "}
          <MapConatiner id="map" />
        </div>
      </MapContainer>
    </ThemeProvider>
  );
}

export default Map;
